$launcher-size: 42px;

$launcher-item-height: 45px;
$launcher-item-prefix-size: 24px;

$launcher-primary-color: #575757;
$launcher-popover-background: #fff;
$launcher-item-hover-color: #f5f5f5;

.zyAppLauncher-widget {
    position: relative;
    margin-right: 0.25rem;
    width: $launcher-size;
    height: $launcher-size;
    cursor: pointer;

    circle {
        fill: $launcher-primary-color;
    }
}

.zyAppLauncher-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    visibility: hidden;
    z-index: 1001;

    &.show {
        visibility: visible;
    }
}

.zyAppLauncher-popover {
    position: absolute;
    margin: 0.5rem;
    width: max-content;
    text-align: left;
    background-color: $launcher-popover-background;
    border-radius: 2px;
    z-index: 1000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 5px;
        width: 250px;
        list-style-type: none;
    }

    li {
        display: flex;
        align-items: center;
        justify-content: left;
        margin: 5px;
        width: 110px;
        line-height: $launcher-item-height;
        cursor: pointer;
    }

    svg {
        width: $launcher-item-prefix-size;
        height: $launcher-item-prefix-size;
    }

    span,
    a {
        overflow-x: hidden;
        max-width: 15rem;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    span {
        line-height: 20px;
    }

    a {
        display: flex;
        align-items: center;
        padding: 10px 15px 12px;
        width: 100%;
        text-decoration: none;
        color: #111;
        flex-direction: column;

        &:hover {
            background-color: $launcher-item-hover-color;
        }
    }
}
